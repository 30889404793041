<article class="message is-centered">
  <div class="message-body">
    Weapons are ranked with optimal forma count, however <strong>rivens are not factored into weapon rankings!!!</strong>
    Read more about <a routerLink="oro">our testing process</a>.
  </div>
</article>
<article class="message is-info is-centered is-hidden-tablet">
  <div class="message-body">
    Try desktop view for a better experience.
  </div>
</article>
