<div class="content">
  <mat-divider></mat-divider>
  <div class="content container">
    <h2 class="mat-headline">i_wanna_b_the_guy's Tier List</h2>
    <p>built with <fa-icon class="animated-heartbeat" [icon]="faHeart"></fa-icon> by the tier list team</p>
    <ul class="list-unstyled">
      <li>author: nader</li>
      <li>curator: <a href="https://reddit.com/u/sakai4eva" target="_blank" rel="noopener">sakai4eva</a></li>
      <li>assistant curators: Quiggsy, Demto</li>
      <li>developers: nader, <a href="https://matthewwang.me" target="_blank" rel="noopener">matt (mattxwang)</a></li>
      <li>thanks to: Kalgash, <a href="https://www.reddit.com/user/zhandragon" target="_blank" rel="noopener">Zhandragon</a>, Brendan, .hallwayRaptor™, Ypqah</li>
    </ul>
    <br />
    <ul class="list-unstyled">
      <li><a href="https://discord.gg/Cq3jW27" target="_blank" rel="noopener"><fa-icon [icon]="faDiscord"></fa-icon> Discord Server</a></li>
      <li><a href="https://github.com/weblue/wanna_b_tierlist" target="_blank" rel="noopener"><fa-icon [icon]="faGithub"></fa-icon> GitHub Repository</a></li>
    </ul>
    <br />
    <p> &copy; i_wanna_b_the_guy / nader 2020 </p>
  </div>
</div>
