export class FilterParams {
  //Item
  name: string;
  base: string;

  mr: number;
  rivenDisp: number;

  buildType: any[];
  triggerType: any[];
  category: any[];
  tier: string[];

  munitions: any[];

  constructor() {

  }
}
