<section class="hero-body is-medium contents">
  <div class="container">
    <p class="title is-2">i_wanna_b_the_guy's Warframe Tier List</p>
    <p class="subtitle is-4 is-italic">
      An opinionated guide to everything Warframe.
    </p>
    <p class="subtitle is-4">
      <strong>Current Version:</strong> {{listVersion}}
    </p>
    <app-disclaimers></app-disclaimers>
  </div>
</section>
<mat-divider></mat-divider>
<br/>
<app-table></app-table>
<br/>
