<!--<div class="hero">-->
<div>
  <div class="level is-marginless" *ngIf="!showSearch">
    <div class="level-left"></div>
    <div class="level-right has-text-right field margin-left margin-right">
        <div class="notification is-light is-info" style="padding: .625em .75em">
          <p class="level-item control">
            <!--        <mat-label>Current Filters:</mat-label>-->
            &nbsp;Filtering: [{{filterDisplay}}]
          </p>
        </div>
    </div>
  </div>
  <div class="level is-marginless">
    <div class="level-left tabs is-large" id="special-nav-tabs">
      <ul class="level-item">
        <li *ngFor="let tab of tabs" (click)="switch(tab.toLowerCase()); activeTab = tab"
            [class.is-active]="activeTab == tab"><a> {{tab}}
        </a>
        </li>
      </ul>
    </div>
    <div class="level-right has-text-right field is-grouped margin-left margin-right">
      <p class="level-item control" *ngIf="showSearch">
        <mat-form-field class="example-full-width">
          <mat-label>Quick Search</mat-label>
          <input matInput name="quick-search" [(ngModel)]="quickSearchName" (ngModelChange)="quickFilter()"/>
        </mat-form-field>
      </p>
      <p class="level-item control">
        <a class="button is-info is-outlined" (click)="toggleFilterDialog()">
          <fa-icon [icon]="faSearch" size="lg"></fa-icon>
          &nbsp;Open Filters</a>
      </p>
      <p class="level-item control" *ngIf="showClear">
        <a class="button is-light is-danger" (click)="clear()">
          <fa-icon [icon]="faTimes" size="lg"></fa-icon>
          &nbsp;Clear Filters</a>
      </p>
    </div>
  </div>
  <div>
    <table class="container is-fullhd table" mat-table matSort [dataSource]="tableDataSource"
           multiTemplateDataRows>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th class="padded" *matHeaderCellDef> Name</th>
        <td mat-cell class="padded" *matCellDef="let element">
          <a class="padded" target="_blank" href="{{element.url}}">{{element.name}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th class="padded" *matHeaderCellDef> Category</th>
        <td mat-cell class="padded" *matCellDef="let element"> {{element.category}} </td>
      </ng-container>

      <ng-container matColumnDef="mr">
        <th class="padded" *matHeaderCellDef> MR</th>
        <td mat-cell class="padded" *matCellDef="let element"> {{element.mr}}</td>
      </ng-container>

      <ng-container matColumnDef="dmg">
        <th class="padded" *matHeaderCellDef> Build Type</th>
        <td mat-cell class="padded" *matCellDef="let element">
          <a target="_blank" href="{{element.dmgLink}}" *ngIf="element.dmg !== 'Critical & Status'">{{element.dmg}}</a>
          <a target="_blank" href="{{element.dmgLink}}" *ngIf="element.dmg === 'Critical & Status'">Crit & Status</a></td>
      </ng-container>

      <ng-container matColumnDef="munitions">
        <th class="padded" *matHeaderCellDef> Munitions</th>
        <td mat-cell class="padded" *matCellDef="let element"> {{element.munitions}} </td>
      </ng-container>

      <ng-container matColumnDef="rivenDisposition">
        <th class="padded" *matHeaderCellDef> Riven Dispo</th>
        <td mat-cell class="padded" *matCellDef="let element"> {{element.rivenDisposition  | number: '1.2-2'}}
          <div class="unusual_riven" *ngIf="element.specRivenDisp">
            ✪
            <span class="tooltip_text">Higher-than-expected riven disposition for this rank</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th class="padded" *matHeaderCellDef> Notes</th>
        <td mat-cell class="padded" *matCellDef="let element"> {{element.notes}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th class="padded" *matHeaderCellDef> Trigger Type</th>
<!--        <td mat-cell class="padded" *matCellDef="let element"> {{element.type}} </td>-->
        <td mat-cell class="padded" *matCellDef="let element">
          <span *ngIf="element.type !== 'Full charge/Detonate'">{{element.type}}</span>
          <span *ngIf="element.type === 'Full charge/Detonate'">F. charge/Detonate</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="perfIndex">
        <th class="padded" *matHeaderCellDef>
          <div>
        <span class="perf_index">Perf Index
          <span
            class="tooltip_text">Performance Index; performance score relative to other weapons. See FAQ for more.</span>
        </span>
          </div>
        </th>
        <td mat-cell class="padded" *matCellDef="let element"> {{element.perfIndex | number: '1.1-1'}} </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th *matHeaderCellDef></th>
        <td mat-cell class="padded" style="width: 92px;" *matCellDef="let element">
          <fa-icon *ngIf="element !== expandedElement" [icon]="faDown" size="lg" class="is-pulled-right"></fa-icon>
          <fa-icon *ngIf="element === expandedElement" [icon]="faUp" size="lg" class="is-pulled-right"></fa-icon>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <!--TODO rename the example CSS-->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" class="row-borders" [attr.colspan]="displayedColumns.length">
          <div class="row-detail top-border" *ngIf="element === expandedElement"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <card-contents style="width: 100%; flex-shrink: 0;" [item]="element"></card-contents>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="tierHeader">
        <td class="padded" [attr.colspan]="displayedColumns.length" mat-cell *matCellDef="let tier">
          <strong>{{tier.name}}</strong></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [hidden]="row.isTier"
          class="element-row top-border"
          [class.row-expanded]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"
                [hidden]="row.isTier"></tr>
      <tr class="sticky-tier" mat-row *matRowDef="let row; columns: ['tierHeader']"
          [hidden]="!row.isTier"></tr>
    </table>
  </div>
</div>
